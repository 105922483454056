import './App.css';
import {Navbar,Footer} from './components'
import {Home,Profile,Item,Item2, Item3, Item4, Create,Login,Register, Dashboard, Success} from './pages'
import { Routes, Route } from "react-router-dom";

function App() {

  return (
    <div>
      {/* <Navbar /> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path=":item/1" element={<Item />} />
            <Route path=":item/2" element={<Item2 />} />
            <Route path=":item/3" element={<Item3 />} />
            <Route path=":item/4" element={<Item4 />} />
            <Route path="/create" element={<Create /> } />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path="/login" element={ <Login />} />
            <Route path="/register" element={ <Register />} />
            <Route path="/dashboard" element={ <Dashboard />} /> 
            <Route path="/success" element={ <Success />} /> 
          </Routes>
      <Footer />
    </div>
  );
}

export default App;
