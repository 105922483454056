import React from 'react';
import './success.css'
import {Link} from 'react-router-dom'
const Success = () => {

  return (
    <div className='login section__padding'>
      <div className="login-container">
        <h1>Thank you for making a request!</h1>
        <form className='login-writeForm' autoComplete='off'>
          
         <div className="login-button">
          <Link to="/">
            <button className='login-writeButton' type='submit'>Return to Home</button>
          </Link>
         </div>
        </form>
      </div>
    </div>
   )
};

export default Success;
