import React from 'react'
import './bids.css'
import { AiFillHeart,AiOutlineHeart } from "react-icons/ai";
import pic1 from '../../assets/beidakcat1.jpg'
import pic2 from '../../assets/beidakcat2.png'
import pic3 from '../../assets/beidakcat3.png'
import pic4 from '../../assets/beidakcat4.png'
import { Link } from 'react-router-dom';

const Bids = ({title}) => {
  return (
    <div className='bids section__padding'>
      <div className="bids-container">
        <div className="bids-container-text">
          <h1>{title}</h1>
        </div>
        <div className="bids-container-card">
          <div className="card-column" >
            <div className="bids-card">
              <div className="bids-card-top">
                <img src={pic1} alt="" />
              <Link to={`/Item/1`}>
              <p className="bids-title">Bust Sketch</p>
              </Link>
              </div>
              <div className="bids-card-bottom">
                <p>Starting at $30 <span></span></p>
              </div>
              <Link to={`/Item/1`}>
              <div className="load-more">
                <button >Request</button>
              </div>
              </Link>
            </div>
          </div>
          <div className="card-column" >
            <div className="bids-card">
              <div className="bids-card-top">
                <img src={pic2} alt="" />
              <Link to={`/Item/2`}>
              <p className="bids-title">Fullbody Sketch</p>
              </Link>
              </div>
              <div className="bids-card-bottom">
                <p>Starting at $60 <span></span></p>
              </div>
              <Link to={`/Item/2`}>
              <div className="load-more">
                <button >Request</button>
              </div>
              </Link>
            </div>
          </div>
          <div className="card-column" >
            <div className="bids-card">
              <div className="bids-card-top">
                <img src={pic3} alt="" />
              <Link to={`/Item/3`}>
              <p className="bids-title">Sketchpage</p>
              </Link>
              </div>
              <div className="bids-card-bottom">
                <p>Starting at $85 <span></span></p>
              </div>
              <Link to={`/Item/3`}>
              <div className="load-more">
                <button >Request</button>
              </div>
              </Link>
            </div>
          </div>
          <div className="card-column" >
            <div className="bids-card">
              <div className="bids-card-top">
                <img src={pic4} alt="" />
              <Link to={`/Item/4`}>
              <p className="bids-title">Experimental Illustration</p>
              </Link>
              </div>
              <div className="bids-card-bottom">
                <p>Starting at $35 <span></span></p>
              </div>
              <Link to={`/Item/4`}>
              <div className="load-more">
                <button >Request</button>
              </div>
              </Link>
            </div>
          </div>
          {/* <div className="card-column" >
            <div className="bids-card">
              <div className="bids-card-top">
                <img src={bids5} alt="" />
              <Link to={`/post/123`}>
              <p className="bids-title">White Line Grafiti</p>
              </Link>
              </div>
              <div className="bids-card-bottom">
                <p>0.09 <span>ETH</span></p>
                <p> <AiFillHeart /> 22</p>
              </div>
            </div>
          </div> */}
          {/* <div className="card-column" >
            <div className="bids-card">
              <div className="bids-card-top">
                <img src={bids6} alt="" />
              <Link to={`/post/123`}>
              <p className="bids-title">Abstract Triangle</p>
              </Link>
              </div>
              <div className="bids-card-bottom">
                <p>0.90 <span>ETH</span></p>
                <p> <AiFillHeart /> 71</p>
              </div>
            </div>
          </div> */}
          {/* <div className="card-column" >
            <div className="bids-card">
              <div className="bids-card-top">
                <img src={bids7} alt="" />
              <Link to={`/post/123`}>
              <p className="bids-title">Lake Landscape</p>
              </Link>
              </div>
              <div className="bids-card-bottom">
                <p>0.52 <span>ETH</span></p>
                <p> <AiFillHeart /> 63</p>
              </div>
            </div>
          </div> */}
          {/* <div className="card-column" >
            <div className="bids-card">
              <div className="bids-card-top">
                <img src={bids8} alt="" />
              <Link to={`/post/123`}>
              <p className="bids-title">Blue Red Art</p>
              </Link>
              </div>
              <div className="bids-card-bottom">
                <p>0.85 <span>ETH</span></p>
                <p> <AiFillHeart /> 66</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className="load-more">
        <button>Load More</button>
      </div> */}
    </div>
  )
}

export default Bids
