import React from 'react';
import './item.css'
import item from '../../assets/beidakcat1.jpg'
import { Link } from 'react-router-dom';

function myFunction() {
  var popup = document.getElementById("myPopup");
  popup.classList.toggle("show");
}

const Item = () => {

  return( 
      <div className='item section__padding'>
        {/* <div className="item-content-detail">
          <div>
              <Link to={'/'}>
                <button className="secondary-btn">
                <img src={Icon2} alt="my image"/>
                </button>
              </Link>
            </div>
          </div> */}
        <div className="item-image">
          <img src={item} alt="item" />
        </div>
          <div className="item-content">
            <div className="item-content-title">
              <h1>Headshot Sketch</h1>
              <a href="https://www.freepik.com/free-vector/four-superheroes-heads-pop-art-style_24631721.htm#query=super%20hero%20art&position=7&from_view=search&track=sph"></a>
              {/* <p>From <span>4.5 ETH</span> ‧ 20 of 25 available</p> */}
            </div>
            {/* <div className="item-content-creator">
              <div><p>Creater</p></div>
              <div>
                <img src={creator} alt="creator" />
                <p>Rian Leon </p>
              </div>
            </div> */}
            <div className="item-content-detail">
              <p>A simple sketch of the head of your character with an expression of your choice, colored or not</p>
            </div>
            <div className="item-content-buy">
              <Link to={`/create`}>
                <button className="primary-btn">Request For $30</button>
              </Link>
              {/* <button className="secondary-btn">Make Offer</button> */}
            </div>
            {/* <div class="popup" onclick="myFunction()">Click me!
              <span class="popuptext" id="myPopup">Popup text...</span>
            </div> */}
          </div>
      </div>
  )
};

export default Item;
