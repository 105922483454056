import React from 'react';
import {Bids, Header, About} from '../../components'


const Home = () => {

  return <div>
   <Header />
   {/* <About/> */}
   <Bids title="Commissions!"  />

  </div>;
};

export default Home;
