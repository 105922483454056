import './create.css'
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as firebase from 'firebase/app';
import 'firebase/database';
import { getDatabase, ref, set, push } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyDJJN1GD_Z8e4V2e9iZqnbqk-IVN4c_ZqM",
  authDomain: "reppd1.firebaseapp.com",
  databaseURL: "https://reppd1-default-rtdb.firebaseio.com",
  projectId: "reppd1",
  storageBucket: "reppd1.appspot.com",
  messagingSenderId: "1021560523023",
  appId: "1:1021560523023:web:52a0a239f81bf6313e967a",
  measurementId: "G-FZKY7XZHMD"
};

firebase.initializeApp(firebaseConfig);

const Create = () => {
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);

  // State for form data
  const [formData, setFormData] = useState({
    driveLink: '',
    fullName: '',
    deadline: '',
    email: '',
    description: '',
    category: '',
    submitted: false,
    status: 'Not Accepted'
  });

  useEffect(() => {
    // Check if all required fields are filled
    const requiredFields = ['driveLink', 'fullName', 'deadline', 'email', 'description', 'category'];
    const hasAllRequiredData = requiredFields.every(field => formData[field] !== '');
  
    if (hasAllRequiredData && formData.submitted) {
      const database = getDatabase();
      writeToFirebase(database, formData);
      // Reset the submitted flag after writing to the database
      setFormData(prevData => ({ ...prevData, submitted: false }));
    }
  }, [formData]);

  const toggleModal = () => {
    setModal(!modal);
  };

  const writeToFirebase = (database, data) => {
    const reference = ref(database, 'tests');
    const newPostRef = push(reference); // Generate a new unique key
    set(newPostRef, data);
  };

  const handleWriteData = (e) => {
    e.preventDefault(); // Prevent the form from submitting and triggering a page reload
  
    // Mark the form as submitted
    setFormData(prevData => ({ ...prevData, submitted: true }));

    const database = getDatabase();
    writeToFirebase(database, formData);

    navigate('/success');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'category' ? e.target.selectedOptions[0].value : value,
    }));
  };

  if (modal) {
    document.body.classList.add('active-modal');
  } else {
    document.body.classList.remove('active-modal');
  }

  return (
    <div className='create section__padding'>
      <div className="create-container">
        <h1>Commission Request Details</h1>
        <form className='writeForm' autoComplete='off'>
          <div className="formGroup">
            <label>Google Drive Link to References</label>
            <input
              type="text"
              name="driveLink"
              placeholder="Google Drive Link"
              autoFocus={true}
              required
              onChange={handleChange}
            />
          </div>
          <div className="formGroup">
            <label>Full Name</label>
            <input
              type="text"
              name="fullName"
              placeholder="Full Name"
              required
              onChange={handleChange}
            />
          </div>
          <div className="formGroup">
            <label>Deadline</label>
            <input
              type="date"
              name="deadline"
              placeholder="Full Name"
              required
              onChange={handleChange}
            />
          </div>
          <div className="formGroup">
            <label>Email</label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              required
              onChange={handleChange}
            />
          </div>
          <div className="formGroup">
            <label>Description</label>
            <textarea
              name="description"
              rows={4}
              placeholder="Description of your item"
              required
              onChange={handleChange}
            />
          </div>
          <div className="formGroup">
            <label>Category</label>
            <select name="category" required onChange={handleChange}>
              <option value="Not Selected">Not Selected</option>
              <option value="Bust Sketch">Bust Sketch</option>
              <option value="Fullbody Sketch">Fullbody Sketch</option>
              <option value="Sketchpage">Sketchpage</option>
              <option value="Experimental Illustration">Experimental Illustration</option>
            </select>
          </div>
          <button onClick={handleWriteData} type="submit" className='writeButton'>Create Item</button>
        </form>
      </div>
    </div>
  );
};

export default Create;