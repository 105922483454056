import React, { useState, useEffect } from 'react';
import './dashboard.css';
import { Link } from 'react-router-dom';
import { getDatabase, ref, onValue, set } from 'firebase/database';
import emailjs from 'emailjs-com';

const Dashboard = () => {
  const [requests, setRequests] = useState([]);
  const [selectedStatusMap, setSelectedStatusMap] = useState({});

  const sendEmail = async (to, status, name) => {
    const templateParams = {
      name,
      reply_to: to,
      notes: `Your request status has been changed to ${status}.`,
    };

    try {
      if (status === 'Rejected') {
        const response = await emailjs.send('service_3pd23jx', 'template_test9fa', templateParams, 'Tb2HHiVyohbTUYzlY');
        console.log('Rejection email sent successfully:', response);
      } else {
        const response = await emailjs.send('service_3pd23jx', 'template_bu8iai2', templateParams, 'Tb2HHiVyohbTUYzlY');
        console.log('Accept email sent successfully:', response);
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const getOptionColor = (requestId) => {
    const colorMap = {
      'Not Accepted': '#3498db',
      'Completed': '#3498db',
      'Accepted': '#52ad28',
      'Rejected': '#e74c3c',
    };

    // Return the color based on the selected status or request status
    const selectedStatus = selectedStatusMap[requestId];
    const requestStatus = requests.find(request => request.id === requestId)?.status;
    return colorMap[selectedStatus] || colorMap[requestStatus] || '#2D2E36';
  };

  useEffect(() => {
    // Function to fetch data from Firebase
    const fetchData = async () => {
      const database = getDatabase();
      const requestsRef = ref(database, 'tests');

      // Attach a listener to update the state when data changes
      onValue(requestsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Convert the data object into an array
          const requestsArray = Object.keys(data).map((id) => ({ id, ...data[id] }));
          setRequests(requestsArray);
        } else {
          setRequests([]);
        }
      });
    };

    // Fetch data when the component mounts
    fetchData();

    // Clean up the listener when the component unmounts
    return () => {
      // Detach the listener to prevent memory leaks
      const database = getDatabase();
      const requestsRef = ref(database, 'requests');
      onValue(requestsRef, null);
    };
  }, []); // Empty dependency array ensures the effect runs once when the component mounts

  const handleStatusChange = (requestId, newStatus) => {
    const database = getDatabase();
    const requestsRef = ref(database, `tests/${requestId}/status`);
    set(requestsRef, newStatus);

    // Send email only for the request whose status was changed to 'Rejected' or 'Accepted'
    const request = requests.find((req) => req.id === requestId);
    if (newStatus === 'Rejected' || newStatus === 'Accepted') {
      sendEmail(request.email, newStatus, request.fullName);
    }
  };

  // Filter requests with status "Not Accepted" or "Rejected" for the bottom table
  const filteredRequests = requests.filter(
    (request) => request.status === 'Accepted' || request.status === true
  );

  const filteredRequests2 = requests.filter(
    (request) => request.status !== 'Completed' && request.status !== 'Accepted' && request.status !== 'Rejected'
  );

  return (
    <div className='login section__padding'>
      <div className="login-container">
        <h1>Reppd Commission Dashboard</h1>
        <div className="dashboard-container">
          <div className="header-box">
            <h3>Active Commisions</h3>
          </div>
          <div className='request-table-container'>
            <div className="request-table"></div>
          </div>
        </div>
        <div className="request-table">
          <table>
            <thead>
              <tr>
                <th>Requester Name</th>
                <th>Requester Email</th>
                <th>Description</th>
                <th>Request Type</th>
                <th>Photo References</th>
                <th style={{ width: '125px' }}>Request Deadline</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredRequests.map((request) => (
                <tr key={request.id}>
                  <td>{request.fullName}</td>
                  <td>{request.email}</td>
                  <td>{request.description}</td>
                  <td>{request.category}</td>
                  <td>
                    <a href={request.driveLink} target="_blank" rel="noopener noreferrer">
                      VIEW
                    </a>
                  </td>
                  <td style={{ width: '125px' }}>{request.deadline}</td>
                  <td>
                    <select
                      value={selectedStatusMap[request.id] || request.status}
                      onChange={(e) => {
                        const newStatusMap = { ...selectedStatusMap, [request.id]: e.target.value };
                        setSelectedStatusMap(newStatusMap);
                        handleStatusChange(request.id, e.target.value);
                      }}
                      style={{ backgroundColor: getOptionColor(request.id) }}
                    >
                      {/* <option value="Not Accepted">Not Accepted</option> */}
                      <option value="Accepted">Accepted</option>
                      {/* <option value="Rejected">Rejected</option> */}
                      <option value="Completed">Completed</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='request-table-background'></div>
        </div>

        <div style={{ paddingTop: '50px' }}>
        </div>
        <div className="dashboard-container">
          <div className="header-box">
            <h3>Pending Requests</h3>
          </div>
          <div className='request-table-container'>
            <div className="request-table"></div>
          </div>
        </div>
        <div className="request-table">
          <table>
            <thead>
              <tr>
                <th>Description</th>
                <th>Request Type</th>
                <th>Photo References</th>
                <th style={{ width: '125px' }}>Request Deadline</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredRequests2.map((request) => (
                <tr key={request.id}>
                  <td>{request.description}</td>
                  <td>{request.category}</td>
                  <td>
                    <a href={request.driveLink} target="_blank" rel="noopener noreferrer">
                      VIEW
                    </a>
                  </td>
                  <td style={{ width: '125px' }}>{request.deadline}</td>
                  <td>
                    <select
                      value={selectedStatusMap[request.id] || ''}
                      onChange={(e) => {
                        const newStatusMap = { ...selectedStatusMap, [request.id]: e.target.value };
                        setSelectedStatusMap(newStatusMap);
                        handleStatusChange(request.id, e.target.value);
                      }}
                      style={{ backgroundColor: getOptionColor(request.id) }}
                    >
                      <option value="Not Accepted">Not Accepted</option>
                      <option value="Accepted">Accepted</option>
                      <option value="Rejected">Rejected</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='request-table-background'></div>
        </div>
        <div style={{ paddingTop: '40px' }}>
        </div>
        <div style={{ textAlign: 'right', paddingTop: '20px' }}>
          {/* Removed the 'Save Changes' button, as changes are directly written to Firebase */}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
