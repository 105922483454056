import React from 'react'
import './header.css'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import beidak1 from '../../assets/beidak1.png'
import beidak2 from '../../assets/beidak2.png'
import beidak3 from '../../assets/beidak3.png'
import beidak4 from '../../assets/beidak4.png'
import beidak5 from '../../assets/beidak5.png'
import beidaknew1 from '../../assets/BeidakNew1.png'
import beidaknew2 from '../../assets/BeidakNew2.png'
import beidaknew3 from '../../assets/BeidakNew3.png'
import beidaknew4 from '../../assets/BeidakNew4.png'
import beidaknew5 from '../../assets/BeidakNew5.png'
import beidaknew6 from '../../assets/BeidakNew6.png'
import beidaknew7 from '../../assets/BeidakNew7.png'
import beidaknew8 from '../../assets/BeidakNew8.png'
import beidaknew9 from '../../assets/BeidakNew9.png'
import beidaknew10 from '../../assets/BeidakNew10.png'
import rita from '../../assets/image-rita.png'
import coin from '../../assets/coin.png'
import pic1 from '../../assets/beidakhead.png'
import { Link  } from 'react-router-dom';
const Header = () => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide:true,
    responsive: [
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          swipeToSlide:true,
        }
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          swipeToSlide:true,
        }
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true,
        }
      }
    ]
  };
  return (
    <div className='header section__padding'>
      <div className="header-content">
        <div>
          <h1>Reppd Commissions</h1>
          {/* <img className='shake-vertical' src={coin} alt="" /> */}
        </div>
      </div>

      <div class = "center">
        <figure class="snip1495">
        <img src={pic1} alt="sq-sample21" />
        <figcaption>
          <h3>Beidak</h3>
          <div class="icons">
            {/* <a href="#"><i class="ion-social-facebook"></i></a> */}
          <a href="https://twitter.com/_beidak_"> <i class="ion-social-twitter"></i></a>
          <a href="https://www.instagram.com/_beidak_/"> <i class="ion-social-instagram"></i></a>
          </div>
        </figcaption>
        </figure>
      </div>

      <div className="header-slider">
        <h1>Portfolio Samples</h1>
        <a href="https://www.freepik.com/free-vector/superhero-square-shadow-icons-set-with-power-symbols_2869690.htm#query=superhero&position=7&from_view=keyword"></a> 
       <Slider {...settings} className='slider'>
            <div className='slider-card'>
              <img src={beidak5}></img>
              {/* <p className='slider-card-number'>1</p> */}
              {/* <div className="slider-img">
                <img src={seller1} alt="" />
                <img src={verify} className='verify' alt="" />
              </div> */}
              {/* <Link to={`/profile/Rian`}>
              <p className='slider-card-name'>James Bond</p>
              </Link> */}
              {/* <p className='slider-card-price'>5.250 <span>ETH</span></p> */}
            </div>
            <div className='slider-card'>
              <img src={beidaknew1}></img>
            </div>
            <div className='slider-card'>
              <img src={beidaknew2}></img>
            </div>
            <div className='slider-card'>
              <img src={beidaknew3}></img>
            </div>
            {/* <div className='slider-card'>
              <img src={beidaknew4}></img>
            </div> */}
            <div className='slider-card'>
              <img src={beidaknew5}></img>
            </div>
            <div className='slider-card'>
              <img src={beidaknew6}></img>
            </div>
            <div className='slider-card'>
              <img src={beidaknew7}></img>
            </div>
            <div className='slider-card'>
              <img src={beidaknew8}></img>
            </div>
            <div className='slider-card'>
              <img src={beidaknew9}></img>
            </div>
            <div className='slider-card'>
              <img src={beidaknew10}></img>
            </div>
        </Slider>
      </div>
    </div>
  )
}

export default Header
